
export const Constants = {
  // JSS
  SecretKey: "trfy56rsf6u5t6t6gjuyvy423eyd543165dwq4",
  //prod
  //DOMAIN: "d3eyik1plsgzx.cloudfront.net",
  //dev
  DOMAIN: "d1hfr1iyugj21x.cloudfront.net",
  //
  //DOMAIN: "dhgdihuh7pgku.cloudfront.net",
  //DOMAIN: "d3cb79yue9i8q3.cloudfront.net",
  //for mauritus
  //DOMAIN: "d1fgflxnncw0ta.cloudfront.net",
  GET_PROGRAM: "/getProgram",
  ALL_PROGRAM: "/getAllProgramList",
  SYNC_PROGRAM: "/syncUserProgramProgress",
  GET_USER_PROGRESS: "/getUserTotalProgress",
  GET_COURSE: "/getCourse",
  SYNC_USER_PROGRESS: "/syncUserProgress",
  GET_APPLICATION: "/getApplication",
  GCP_RESPONSE: "/getGoogleCloudResponse",
  GET_HTTP_RESPONSE: "/getHttpResponse",
  ANALYTICS_WEB_APP: "/analyticsWebApp",
  GET_QUIZ: "/getQuiz",
  GET_EVENTS: "/getEvents",
  POST_ANNOUNCEMENT: "/PostAnnouncement",
  GET_NOTIFICATIONS: "/getNotifications",
  GET_ASSESSMENT: "/getAssessment",
  GET_ASSIGNED_TESTS: "/getAssignedTests",
  GET_TEST_LINK: "/getTestLink",
  GET_WHEEBOX_TOKEN: "/getWheeboxToken",
  GET_ASSESSMENT_ANSWERS: "/getAssessmentQuiz",
  UPDATE_ASSESSMENT: "/updateAssessment",
  UPDATE_MINI_ASSIGNMENT: "/uploadAssignment",
  GET_ASSESSMENT_QUIZ: "/getAssessmentQuiz",
  GET_ASSIGNMENT_DATA: "/getAssignmentData",
  REFERENCES: "/references",
  GET_FEEDBACK: "/getfeedback",
  UPDATE_FEEDBACK: "/updatefeedback",
  GET_DISCUSSION_DATA: "/getDiscussion",
  UPDATE_DISCUSSION: "/updateDiscussion",
  GET_COURSE_VIDEO: "/getcoursevideo",
  GET_USN: "/getusn",
  GET_ANNOUNCEMENT_FILE: "/getAnnouncementFile",
  GET_PROGRAMS: "/getPrograms",
  GET_PRESIGNED_URL: "/getPreSignedURL",
  UPDATE_USER_APPLICATION: "/updateUserApplication",
  QUIZ_SCORE: "/getQuizScore",
  POST_QUIZ: "/postQuizScore",
  INDIAN_TIME: "/indianTimeZone",
  GET_COURSE_DETAILS_FOR_USER: "/getCourseDetailsForUser",
  CIFeedBack: "/CIFeedBack",
  getCourseInstructor: "/getCourseInstructor",
  getBatchID: "/getBatchID",
  GET_TEST_RESULT: "/getTestResult",

  // JSS
};
