import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, } from "semantic-ui-react";
import Tooltip from "@material-ui/core/Tooltip";
import ReactPlayer from "react-player";
import { awsSignIn, authData, } from "../../redux/auth/authSlice";
import useWindowDimensions from "../../modules/Window/Window";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import { API, } from "aws-amplify";
import "./CoursePlayer.scss";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import { IconButton } from "@material-ui/core";
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit";
import Fullscreen from "@material-ui/icons/Fullscreen";
import Fade from "@material-ui/core/Fade";
import screenfull from "screenfull";
import axios from "axios";
import produce from "immer";
import Formative from "../../components/CourseStructure/Quize/Formative";
import FormativeFeedback from "../../components/CourseStructure/Quize/FormativeFeedback";
import Summative from "../../components/CourseStructure/Quize/Summative";
import MiniAssignment from "../../components/CourseStructure/Quize/MiniAssignment";
import EditMiniAssignment from "../../components/CourseStructure/Quize/EditMiniAssignment";
import { Avatar, } from "@material-ui/core";
import FeedBack from "./FeedBack";
import swal from "sweetalert";
import CircularProgress from "@material-ui/core/CircularProgress";

const CoursePlayer = (props) => {
  let {
    handleClose,
    totalminiScore,
    courseDetails,
    setCourseDetails,
    objectData,
    setObjectData,
    oIndex,
    setOIndex,
    curObject,
    setcurObject,
    curObRef,
    assignment,
    summative,
    sumScore,
    miniScore,
    sMiniScore,
    qisLoading,
    setQIsLoading,
    getQuizScore,
    getAssignmentDataPost,
    assignmentLoad,
    setAssignmentLoad,
    setSummative,
    setFeedback,
    feedback,
    feedbackF,
    setCInstr,
    instr,
    setFOpen,
    questions,
    cinstr,
    cri,
    showCommentBox,
    lcourseDetails,
    setQuestions,
    setShowCommentBox,
    setCri,
    open1,
    setOpen1,

  } = props;


  const [quizQuestion, setQuizQuestion] = useState([]);
  const [btnClick, setBtnClick] = useState(false);
  const [qtype, setQtype] = useState("");
  const [answerReveal, setAnswerReveal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const formData = useRef([]);
  const full = useRef(null);
  const [miniopen, setMiniopen] = useState(false);
  const [editOpenConfirm, setEditOpenConfirm] = useState(false);
  const { height } = useWindowDimensions();
  const [fullscreen, setFullScreen] = useState(true);
  const [tryAgain, setTryAgain] = useState(false);
  const [formativeAns, setFormativeAns] = useState([]);
  const [isSubmittingg, setSubmittingg] = useState(false);
  const [cutOff, setCutOff] = useState("");
  const [ansFcount, setAnsFcount] = useState(0);
  const [correct, setCorrect] = useState();
  const [checkboxes, setCheckboxes] = useState([]);
  const [resubmittedData, setresubmittedData] = useState();
  const [feedbackButton, setFeedbackButton] = useState({
    disableSubmit: true,
    hideNext: true,
    hideSubmit: false,
    submitted: false,
  });


  const [editmini, setEditmini] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [markObjectAsComplete, setMarkObjectAsCompleted] = useState(false);
  const [open, setOpen] = useState(false);
  const [miniloader, setMiniloader] = useState(false);
  const submitScore = useRef(0);
  const [openAns, setOpenAns] = useState(false);
  const startTime = useRef(0);
  const [quizQuestionNew, setQuizQuestionNew] = useState();
  const [questionArray, setQuestionArray] = useState([]);
  const [pissueTextUpload, setPIssueTextUpload] = useState("");
  const [deadLine, setDeadLine] = useState(false);
  const [openMiniFeedback, setOpenMiniFeedback] = useState(false);
  const [openFeedback, setOpenFeedback] = useState(false);
  const [spin, setSpin] = useState(false);
  const [showMarkComplete, setshowMarkComplete] = useState(false);
  const [feedbackSubmited, setFeedbackSubmited] = useState(false);

  let userDetails = useSelector(authData);

  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      "& .MuiTextField-root": {
        color: "black !important",
      },

      "& .MuiFormLabel-root": {
        color: "black !important",
      },
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    textarea11: {
      "& .MuiInputBase-input": {
        color: " #black !important",
        fontSize: "12.5px !important",
      },
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      height: "200px",
      width: "500px",
      borderRadius: "4px",
      border: "none",
      padding: theme.spacing(2, 4, 3),
    },
    modalConfirm: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      border: "none",
      transform: "matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1)",
    },

    tooltipFull: {
      fontSize: 30,
    },
    bottomIcons: {
      color: "#000",
      "&:hover": {
        color: "#777",
      },
    },
    volumeButton: {
      color: "#000",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    volumeButtonExit: {
      color: "lightgray",
      fontSize: 40,
      transform: "scale(0.9)",
      "&:hover": {
        color: "#777",
        transform: "scale(1.03)",
        transitionTimingFunction: "cubic-bezier(0.1, 0.1, 0.25, 1)",
      },
    },
    videoSection: {
      "@media (min-width: 500px)": {
        height: "58vh",
      },
      "& > div": {
        height: "100%",
        alignItems: "center",
        textAlign: "center",
        width: "80%",
        margin: "auto",
        "@media (max-width: 500px)": {
          height: "60vh",
          width: "100%",
        },
      },
      "& > div > div": {
        "@media (max-width: 500px)": {
          height: "fit-content!important",
        },
        "@media (min-height: 800px)": {
          height: "fit-content!important",
        },
        "& > video": {
          width: "100vh!important",
        },
      },
    },
    myIframe: {
      height: "100%",
      width: "100%",
    },
    myIframeContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "96%",
      overflowX: "hidden",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    startTime.current = Math.round(new Date().getTime());
    setLoaded(true);

  }, []);

  const convertToArray = (qitems) => {
    let convertQuestion = [];
    for (let i = 0; i < qitems.length; i++) {
      const myString = qitems[i].istem;
      const myArray = myString.split(" ");
      convertQuestion.push(myArray);
    }

    return convertQuestion;
  };

  async function getQuiz(obj) {
    setAnsFcount(0);
    setAssignmentLoad(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: obj.oid,
        oid: config.aws_org_id,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_QUIZ,
        bodyParam
      );

      const { qitems, qtype } = response;

      setQtype(qtype);
      setQuizQuestion(qitems);
      let res = convertToArray(qitems);
      setQuestionArray(res);

      response.cutoff === undefined
        ? (response.cutoff = qitems.length / 2)
        : setCutOff(response.cutoff);

      setCutOff(response.cutoff);

      if (response.deadLine != undefined) {
        const newDate = new Date(response.deadLine);
        const currentDate = new Date();
        if (currentDate > newDate) {
          setDeadLine(true);
        }
      }

      setBtnClick(true);
      setQIsLoading(false);
      setAssignmentLoad(false);
    } catch (error) {
      console.error(error);
    }
  }

  async function syncUserProgress(userProgressData, obj1) {
    let lcourseDetails = userDetails.curprgcou;

    console.log("userProgressData", userProgressData)
    console.log("obj1", obj1)
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        pid: lcourseDetails.pid,
        bpid: lcourseDetails.bpid,
        courseid: lcourseDetails.tid,
        bcourseid: lcourseDetails.bcid,
        ptitle: lcourseDetails.ptitle,
        courseProgress: userProgressData,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.SYNC_USER_PROGRESS,
        bodyParam
      );
      console.log("BODYDYDYYDYDDYDYDYY", bodyParam.body)
      if (obj1 !== undefined) {
        if (obj1.qtype == 3) {
          if (
            obj1.miniassignmenttype == undefined ||
            obj1.miniassignmenttype == 2 ||
            obj1.miniassignmenttype == "2" ||
            obj1.miniassignmenttype == 3 ||
            obj1.miniassignmenttype == "3" ||
            obj1.miniassignmenttype == 4 ||
            obj1.miniassignmenttype == "4"
          ) {
            setOpen(false);
            setMiniloader(false);
            setShowScore(true);
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  function setMarkComplete() {

    setTimeout(() => {
      if (
        curObRef.current.userOP.op == 0 &&
        (courseDetails.freenavigation == "false" ||
          courseDetails.freenavigation == false)
      ) {
        setMarkObjectAsCompleted(false);
      } else if (curObRef.current.userOP.op != 2) {
        let check = checkQuiz(curObRef.current);



        if (check) {
          if (markObjectAsComplete == false) {
            setMarkObjectAsCompleted(true);
          }
        }
      }
    }, 5000);
  }

  function checkQuiz(obj) {


    if (obj.otype === "quiz") {
    }
    return true;
  }

  function renderObjects() {
    let objType = curObject.otype;

    if (
      curObject.userOP.op == 0 &&
      (courseDetails.freenavigation == "false" ||
        courseDetails.freenavigation == false)
    ) {
      let tobjectData = [...objectData];
      let toIndex = oIndex;
      if (toIndex != 0) {
        if (tobjectData[toIndex - 1].userOP.op == 2) {
          if (markObjectAsComplete == false) {
            setTimeout(() => {
              setMarkObjectAsCompleted(true);
            }, 5000);
          }
        } else {
          return LockView();
        }
      } else {
        return LockView();
      }

    } else if (curObject.userOP.op != 2) {
      setMarkComplete();
    }

    switch (objType) {
      case "video":
        return VideoView(); // loadVideoView(curObject);
      case "audio":
        return AudioView(); // loadAudioView(curObject);
      case "pdf":
        return PdfView(); // loadMultimediaView(curObject);
      case "html":
        return WebView(); // loadMultimediaView(curObject);
      case "Interactivity":
        return WebView();
      case "interactivity":
        return WebView(); // loadInteractivityView(curObject);
      case "scorm":
        return WebView(); // loadscromView(curObject);
      case "quiz":
        return quizView();
      case "vimeo":
        return VimeoView(); // loadVimeoView(curObject);
      case "youtube":
        return youtubeView(); // loadYoutubeView(curObject);
      default:
        return null;
    }
  }

  function renderButtons() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-around",
        }}
      >
        <div>
          {oIndex === 0 ? (
            <div></div>
          ) : navigator.onLine ? (
            <Button
              onClick={prev}
              basic
              content="Prev"
              style={{ fontWeight: "bold", fontSize: "14px " }}
              color="blue"
            />
          ) : null}
        </div>
        <div>
          {markObjectAsComplete == true ? (
            curObject.qtype == 2 || curObject.qtype == 1 || curObject.qtype == "course" || curObject.qtype == "instructor" ? (
              <div></div>
            ) : navigator.onLine ? (
              <Button
                onClick={markComplete}
                basic
                content="Mark Complete"
                color="blue"
                style={{ fontWeight: "bold", fontSize: "14px " }}
              />
            ) : null
          ) : (
            <div></div>
          )}
        </div>
        <div>
          {curObject.userOP.op == 2 ? (
            navigator.onLine ? (
              <Button
                onClick={next}
                basic
                content="Next"
                style={{ fontWeight: "bold", fontSize: "14px " }}
                color="blue"
              />
            ) : null
          ) : null}
        </div>
      </div>
    );
  }

  function prev() {
    setCurrentQuestion(0);
    setMarkObjectAsCompleted(false);
    setBtnClick(false);
    setTryAgain(true);
    setFormativeAns([]);
    setFeedbackButton({
      disableSubmit: true,
      hideNext: true,
      hideSubmit: false,
      submitted: false,
    });

    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex > 0) {
      toIndex = toIndex - 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
      }
    }

    saveredux(toIndex);
  }

  function next() {
    setBtnClick(false);
    setFeedbackButton({
      disableSubmit: true,
      hideNext: true,
      hideSubmit: false,
      submitted: false,
    });
    setMarkObjectAsCompleted(false);
    setAnswerReveal(false);
    setTryAgain(true);
    setFormativeAns([]);
    setCurrentQuestion(0);


    let tobjectData = [...objectData];
    let toIndex = oIndex;

    startTime.current = Math.round(new Date().getTime());

    let l = tobjectData.length - 1;
    if (toIndex < l) {
      toIndex = toIndex + 1;
      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);
      curObRef.current = tobjectData[toIndex];
      if (tobjectData[toIndex].otype === "quiz") {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
      }
    }
    saveredux(toIndex);
  }

  function markComplete() {
    setMarkObjectAsCompleted(false);
    setBtnClick(false);
    setCurrentQuestion(0);

    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;

    let userProgressEndTime = Math.round(new Date().getTime());
    let totalTimeTaken = userProgressEndTime - startTime.current;
    totalTimeTaken = totalTimeTaken / 1000;

    tcourseDetails.userProgressData[tcourseDetails.btid].objects[
      tobjectData[toIndex].oid
    ].op = 2;

    tobjectData[toIndex].userOP.op = 2;

    tcourseDetails.userProgressData[tcourseDetails.btid].objects[
      tobjectData[toIndex].oid
    ].timespent = totalTimeTaken;

    tobjectData[toIndex].userOP.timespent = totalTimeTaken;

    let l = tobjectData.length - 1;
    if (toIndex < l) {
      toIndex = toIndex + 1;

      if (
        tcourseDetails.userProgressData[tcourseDetails.btid].objects[
          tobjectData[toIndex].oid
        ].op != 2
      ) {
        tcourseDetails.userProgressData[tcourseDetails.btid].objects[
          tobjectData[toIndex].oid
        ].op = 1;
        tobjectData[toIndex].userOP.op = 1;
      }

      setOIndex(toIndex);
      setcurObject(tobjectData[toIndex]);

      if (tobjectData[toIndex].otype === "quiz" && (tobjectData[toIndex].qtype != "course" || tobjectData[toIndex].qtype != "instructor")) {
        getAssignmentDataPost(tobjectData[toIndex].oid);
        getQuizScore(tobjectData[toIndex]);
        formData.current = [];
      }

      curObRef.current = tobjectData[toIndex];
    } else if (toIndex == l) {
      if (
        tcourseDetails.freenavigation == "true" ||
        tcourseDetails.freenavigation == true
      ) {
        tcourseDetails.userProgressData[tcourseDetails.btid].tp = 2;

      } else {
        let length = 0;
        for (let i = 0; i < tobjectData.length; i++) {
          if (tobjectData[i].userOP.op == 2) {
            length++;
          } else if (tobjectData[i].userOP.op == 0) {
            tcourseDetails.userProgressData[tcourseDetails.btid].objects[
              tobjectData[i].oid
            ].op = 1;
            tobjectData[i].userOP.op = 1;
          }
        }
        if (length == tobjectData.length) {
          tcourseDetails.userProgressData[tcourseDetails.btid].tp = 2;

        } else {
          tcourseDetails.userProgressData[tcourseDetails.btid].objects[
            tobjectData[toIndex].oid
          ].op = 1;
          tobjectData[toIndex].userOP.op = 1;

        }
      }
    }

    setCourseDetails(tcourseDetails);
    setObjectData(tobjectData);
    syncUserProgress(tcourseDetails.userProgressData);
    saveredux(toIndex);
  }

  function saveredux(toIndex) {
    let sdata = { ...userDetails };
    sdata.oindex = toIndex;
    dispatch(awsSignIn(sdata));

  }

  function LockView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            height: height - 400,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <h1>Please Mark Complete previously viewed object.</h1>
        </div>
      </div>
    );
  }
  function unitLockedView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <h1>This Unit is disabled from Admin</h1>
        </div>
      </div>
    );
  }

  function VideoView() {
    return (
      <div
        className="player-wrapper"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <ReactPlayer
          url={curObject.ourl}
          controls={true}
          width="100%"
          height={height - 300}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    );
  }

  function AudioView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.ourl}
            controls={true}
            width="100%"
            height={height - 300}
          />
        </div>
      </div>
    );
  }

  function VimeoView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function youtubeView() {
    return (
      <div>
        <div
          className="player-wrapper"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <ReactPlayer
            url={curObject.url}
            controls={true}
            width="calc(100% - 15%)"
            height="calc(100% - 20px)"
          />
        </div>
      </div>
    );
  }

  function PdfView() {

    return (
      <div className={classes.myIframeContainer}>
        <iframe src={curObject.ourl} className={classes.myIframe}></iframe>

      </div>
    );
  }

  function WebView() {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",

          justifyContent: "center",
        }}
      >
        <iframe
          src={curObject.ourl}
          className={classes.myIframe}

          title="WebView"
        />
      </div>
    );
  }

  function toggleFullScreen() {
    screenfull.toggle(full.current);
  }

  function onFullScreen() {
    setFullScreen(!fullscreen);
  }

  if (quizQuestion == undefined) {

    handleClose();
    return;
  }

  function handleAnswerOption(ques) {
    return ques;
  }

  const getAssignmentDataPost1 = async (quizid) => {
    setAssignmentLoad(true);
    setQIsLoading(true);
    const bodyParam = {
      body: {
        quizid: quizid,
        oid: config.aws_org_id,
        eid: userDetails.eid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.GET_ASSIGNMENT_DATA,
        bodyParam

      );


      if (response.errorMessage !== undefined) {
        var obj = { oid: quizid };
        getQuiz(obj);
      } else {
        setQuizQuestion(response.response);
        setFeedback(response.feedback);
        setresubmittedData(response.response);
      }

      setQIsLoading(false);
      setEditmini(true);
    } catch (error) {
      console.error("getCategoryError", error);
    }
  };

  function miniEditStatusUpdated(obj1) {
    let tcourseDetails = JSON.parse(JSON.stringify(courseDetails));
    let tobjectData = [...objectData];
    let toIndex = oIndex;
    if (
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited != undefined &&
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited == 0
    ) {
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited = 1;
    } else if (
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited != undefined &&
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited == 1
    ) {
      tcourseDetails.userProgressData[tcourseDetails.btid].objects[
        tobjectData[toIndex].oid
      ].edited = 2;
    }
    setCourseDetails(tcourseDetails);
    syncUserProgress(tcourseDetails.userProgressData, obj1);
  }

  async function saveResponse(data, obj1, autoscore) {
    const obj = {};
    setMiniopen(false);
    if (obj1.qtype == 3) {
      setShowScore(true);
    }

    if (feedback != undefined) {
      obj.feedback = feedback;
      obj.resubmittedData = resubmittedData;
    }
    if (autoscore != undefined) {
      obj.score = autoscore;
    }
    obj.response = data;
    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        quizdata: obj,
        eid: userDetails.eid,
        objid: obj1.oid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.UPDATE_MINI_ASSIGNMENT,
        bodyParam
      );
      if (obj1.qtype == 3) {
        if (
          obj1.miniassignmenttype == undefined ||
          obj1.miniassignmenttype == 2 ||
          obj1.miniassignmenttype == "2" ||
          obj1.miniassignmenttype == 3 ||
          obj1.miniassignmenttype == "3" ||
          obj1.miniassignmenttype == 4 ||
          obj1.miniassignmenttype == "4"
        ) {
          miniEditStatusUpdated(obj1);
        } else {
          setOpen(false);
          setMiniloader(false);
          setShowScore(true);
        }
      } else {
        setOpen(false);
        setMiniloader(false);
        setShowScore(true);
      }
    } catch (error) {
      console.error("getCategoryError", error);
    }
  }

  function autoScorCal(quizAns, obj) {
    var loop = 0;
    let autoscore = 0;
    quizAns.forEach((element) => {
      loop++;
      if (
        element.iopts != undefined &&
        (element.iopts[element.response].correct == "true" ||
          element.iopts[element.response].correct == true)
      ) {
        autoscore += parseInt(element.qscore);
      }
      if (quizAns.length == loop) {
        saveResponse(quizAns, obj, autoscore);
      }
    });
  }

  async function postQuizScorePost(score) {
    let objsr = {};
    let lcourseDetails = userDetails.curprgcou;

    const bodyParam = {
      body: {
        eid: userDetails.eid,
        scorejson: objsr,
        oid: config.aws_org_id,
        rtype: "put",
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    if (lcourseDetails.bpid !== undefined) {
      let date = new Date();
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let date1 = date.getDate();
      let sdate = date1 + "." + month + "." + year;
      objsr.bpid = lcourseDetails.bpid;
      objsr.name = curObject.otitle;
      objsr.cdate = sdate;
      objsr.score = score;
      objsr[curObject.oid] = score;
      bodyParam.body.scorejson = objsr;
    }

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.POST_QUIZ,
        bodyParam
      );

      getQuizScore(curObject);
      markComplete();
    } catch (error) {
      console.error("getCategoryError", error);
    }
  }

  function modallans() {
    const handleClosePop = () => {
      setOpen(false);
    };
    const showScore = () => {
      setOpen(false);
      setShowScore(true);

      saveResponse(formData.current, curObject);
    };
    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <div
              style={{
                backgroundColor: "white",
                height: "80%",
                width: "90%",
                margin: "auto",
              }}
            >
              <h3 style={{ padding: "33px 33px 0px 63px", fontSize: "20px" }}>
                Are you sure you want to submit?
              </h3>
              <button
                style={{
                  position: "relative",
                  right: "40px",
                  top: "70px",
                  color: "#3372B5",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "right",
                  width: "100px",
                  border: "2px solid #3372B5",
                }}
                onClick={() => {
                  showScore();

                }}
              >
                Confirm
              </button>
              <br />
              <button
                style={{
                  position: "relative",
                  right: "-28px",
                  top: "55px",
                  color: "lightcoral",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  height: "28px",
                  lineHeight: "13px",
                  float: "left",
                  width: "100px",
                  border: "2px solid lightcoral",
                }}
                onClick={() => {
                  setBtnClick(false);

                  setScore(0);
                  setShowScore(false);
                  setCurrentQuestion(0);
                  handleClosePop();
                }}
              >
                Cancel
              </button>
            </div>
          </Fade>
        </Modal>
      </>
    );
  }

  function modalFeedBack() {
    const handleClosePop = () => {
      setOpenFeedback(false);
    };

    return (
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalConfirm}
          open={openFeedback}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={openFeedback}>

            <div>
              {spin === true ? (
                <div className={"spinLoad"}>
                  <CircularProgress
                    style={{
                      position: "fixed",
                      top: "48%",
                      left: "48%",
                      background: "transparent",
                      zIndex: "2001",
                    }}
                  />
                </div>
              ) : null}


              <FeedBack
                close={handleClosePop}
                Questions={questions}
                cri={cri}
                feedbackF={getUpdatefeedback}
                cinstr={cinstr}
                showCommentBox={showCommentBox}
                markComplete={markComplete}
                handleClose={handleClose}
                setOpen1={setOpen1}
                showMarkComplete={showMarkComplete}
                setshowMarkComplete={setshowMarkComplete}
                feedbackSubmited={feedbackSubmited}

              />
            </div>

          </Fade>
        </Modal>
      </>
    );
  }

  function setCheckbox(index, checked) {
    let newCheckboxes = [...checkboxes];
    if (newCheckboxes[index].checked == undefined) {
      newCheckboxes = produce(newCheckboxes, (draft) => {
        draft[index].checked = false;
      });
    }

    newCheckboxes = produce(newCheckboxes, (draft) => {
      draft[index].checked = checked;
    });
    setCheckboxes(newCheckboxes);

    let response = [];
    response.push(null);
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].response = response;
        v[currentQuestion].iopts[index].checked = "true";
      })
    );

    let tmp = 0;
    for (let k = 0; k < checkboxes.length; k++) {
      if (
        checkboxes[k].checked !== undefined &&
        checkboxes[k].checked === true
      ) {
        tmp = 1;
        setCorrect(true);
      }
      if (tmp === 0 && k == checkboxes.length - 1) {
        setCorrect(undefined);
      }
    }

    checkboxes.forEach(function (element, index, array) {
      if (element.checked === true || element.checked === "true") {
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].response.push(index);
          })
        );
      }
    });

  }

  const handleNextQuestionClick = (anscount, scoreTemp) => {

    const nextQuestion = currentQuestion + 1;
    let qlength = quizQuestion.length - 1;
    let userAnscount = ansFcount;
    if (curObject.qtype == 2 || curObject.qtype == "2") {
      qlength = quizQuestion.length;
      userAnscount = anscount;
    }
    if (curObject.qtype == 1 || curObject.qtype == "1") {
      qlength = quizQuestion.length;
      userAnscount = anscount;
    }

    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);
    } else if (curObject.qtype == 3) {
      setOpen(true);
    }


    if (userAnscount === qlength) {
      setShowScore(true);

      saveResponse(formData.current, curObject);
      if (qtype === 1 || qtype === "1") {
        let percent = (scoreTemp / quizQuestion.length) * 100;
        percent = Math.round(percent);

        postQuizScorePost(percent);
      }
    }
  };

  const handleMiniDesOption = (correct, index) => {
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].response = index;
      })
    );
    setCorrect(correct);
  };

  const handleAnswerOptionClick = (ansOpts, idx, anscount, scoreTemp) => {
    if (ansOpts.correct === "true" || ansOpts.correct === true) {
      if (curObject.qtype == 1 || curObject.qtype == 2) {
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].lastanswer = "true";
          })
        );
        if (
          quizQuestion[currentQuestion].Qsubmit == undefined ||
          quizQuestion[currentQuestion].Qsubmit == "false"
        ) {
          setScore(score + 1);
          submitScore.current += 1;
        }
      } else {
        setScore(score + 1);
      }
    } else {
      if (curObject.qtype == 1 || curObject.qtype == 2) {
        if (
          quizQuestion[currentQuestion].lastanswer != undefined &&
          quizQuestion[currentQuestion].lastanswer == "true"
        ) {
          setQuizQuestion((currentQues) =>
            produce(currentQues, (v) => {
              v[currentQuestion].lastanswer = "false";
            })
          );
          setScore(score - 1);
          submitScore.current -= 1;
        }
        setQuizQuestion((currentQues) =>
          produce(currentQues, (v) => {
            v[currentQuestion].Qsubmit = "false";
          })
        );
      }
    }
    let answer = [...formativeAns];
    answer.push(idx);
    setFormativeAns(answer);

    handleNextQuestionClick(anscount, scoreTemp);
  };

  const imgUrl =
    config.aws_org_id == "JSSAHER"
      ? `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${userDetails?.curprgcou?.tid}/`
      : `https://${Constants.DOMAIN
      }/${config.aws_org_id.toLowerCase()}-resources/images/quiz-images/${userDetails?.curprgcou?.tid}/`;


  async function getUpdatefeedback(crir, act, resp, irdt) {

    const bodyParam = {
      body: {
        oid: config.aws_org_id,
        eid: userDetails.eid,
        crir: crir,
        resp: resp,
        bcid: lcourseDetails.bcid,
        action: act,
        irdt: irdt,
        bpid: lcourseDetails.bpid,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    setSpin(true)
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        Constants.CIFeedBack,
        bodyParam
      );

      if (act == "get") {
        if (response.code == 1) {
          // swal("Feedback already submitted");
          setFeedbackSubmited(true)
          setshowMarkComplete(true)
          setOpenFeedback(true)

        }

        else {
          setshowMarkComplete(false)
          setQuestions(response.q);
          setCri(crir);
          setOpenFeedback(true)

          if (lcourseDetails.bpid == "0378079387" || lcourseDetails.bpid == "0372847102") {
            setShowCommentBox(true)
          }

        }
      } else {
        setCInstr("");
      }

    } catch (error) {
      console.error(error);
    }

    setSpin(false)
  }

  async function handleImageChange(event) {
    setPIssueTextUpload("");

    if (event.target.files[0].size > 500 * 1024) {
      setPIssueTextUpload("file size is greater than 500kb");
      return;
    }
    setSubmittingg(true);
    let pfileName = new Date().getTime();
    let filename = "auto" + pfileName + event.target.files[0].name;
    let filetype = event.target.files[0].type;
    let quest = handleAnswerOption(quizQuestion[currentQuestion]);
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].filename = filename;
        v[currentQuestion].uploadedfilename = event.target.files[0].name;
        v[currentQuestion].image = true;
        v[currentQuestion].imageurl = quest.imageurl;
      })
    );

    if (event.target.files[0]) {
      const bodyParam = {
        body: {
          type: "minassign",
          filename: filename,
          filetype: filetype,
          oid: config.aws_org_id,
          eid: userDetails.eid,
          quizid: curObject.oid,
        },
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      };

      try {
        var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.pdf)$/i;

        if (!allowedExtensions.exec(filename)) {
          return false;
        }

        const response = await API.post(
          config.aws_cloud_logic_custom_name,
          Constants.GET_PRESIGNED_URL,
          bodyParam
        );
        fileUpload(event.target.files[0], response);
        setSubmittingg(false);
      } catch (error) {
        console.error("getCategoryError", error);
      }
    }
  }

  async function fileUpload(file, url) {
    await axios
      .put(url, file, { headers: { "Content-Type": file.type } })
      .then((res) => { })
      .catch((err) => {
        console.error(err);
      });
  }

  function answerPrev() {
    const prevQuestion = currentQuestion - 1;
    setCurrentQuestion(prevQuestion);
  }

  function answerNext() {
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < quizQuestion.length) {
      setCurrentQuestion(nextQuestion);

      if (
        quizQuestion[nextQuestion].atype == 3 ||
        quizQuestion[nextQuestion].atype == 5
      ) {
        setCorrect(undefined);
        setCheckboxes(quizQuestion[nextQuestion].iopts);
      }
    }
  }

  function quizView() {
    return (
      <div className="body">
        <div className="heading1">


          {
            (curObject.qtype !== "course" && curObject.qtype !== "instructor") && (
              <h1
                style={{
                  justifyContent: "start",
                  color: "black",
                }}
              >
                {curObject.otitle}
              </h1>
            )
          }

        </div>

        {curObject.qtype == 0 ? (
          <FormativeFeedback
            Button={Button}
            imgUrl={imgUrl}
            produce={produce}
            getQuiz={getQuiz}
            btnClick={btnClick}
            setScore={setScore}
            curObject={curObject}
            answerPrev={answerPrev}
            ansFcount={ansFcount}
            answerNext={answerNext}
            handleCheck={handleCheck}
            setTryAgain={setTryAgain}
            qisLoading={qisLoading}
            setBtnClick={setBtnClick}
            handleClose={handleClose}
            setAnsFcount={setAnsFcount}
            markComplete={markComplete}
            setcurObject={setcurObject}
            setShowScore={setShowScore}
            quizQuestion={quizQuestion}
            answerReveal={answerReveal}
            setQIsLoading={setQIsLoading}
            feedbackButton={feedbackButton}
            currentQuestion={currentQuestion}
            setFormativeAns={setFormativeAns}
            setAnswerReveal={setAnswerReveal}
            setQuizQuestion={setQuizQuestion}
            setFeedbackButton={setFeedbackButton}
            setCurrentQuestion={setCurrentQuestion}
            getAssignmentDataPost={getAssignmentDataPost}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 1 ? (
          <Summative
            score={score}
            imgUrl={imgUrl}
            Button={Button}
            produce={produce}
            getQuiz={getQuiz}
            sumScore={sumScore}
            btnClick={btnClick}
            summative={summative}
            curObject={curObject}
            showScore={showScore}
            ansFcount={ansFcount}
            setAnsFcount={setAnsFcount}
            answerPrev={answerPrev}
            answerNext={answerNext}
            handleClose={handleClose}
            qisLoading={qisLoading}
            setShowScore={setShowScore}
            setcurObject={setcurObject}
            quizQuestion={quizQuestion}
            formativeAns={formativeAns}
            setQIsLoading={setQIsLoading}
            setQuizQuestion={setQuizQuestion}
            currentQuestion={currentQuestion}
            setFeedbackButton={setFeedbackButton}
            handleAnswerOptionClick={handleAnswerOptionClick}
            setAnswerReveal={setAnswerReveal}
            answerReveal={answerReveal}
            setCurrentQuestion={setCurrentQuestion}
            setBtnClick={setBtnClick}
            openAns={openAns}
            setOpenAns={setOpenAns}
            quizQuestionNew={quizQuestionNew}
            setQuizQuestionNew={setQuizQuestionNew}
            setSummative={setSummative}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 2 ? (
          <Formative
            score={score}
            cutOff={cutOff}
            Button={Button}
            imgUrl={imgUrl}
            getQuiz={getQuiz}
            setScore={setScore}
            btnClick={btnClick}
            tryAgain={tryAgain}
            produce={produce}
            curObject={curObject}
            ansFcount={ansFcount}
            setAnsFcount={setAnsFcount}
            showScore={showScore}
            setTryAgain={setTryAgain}
            setBtnClick={setBtnClick}
            handleClose={handleClose}
            qisLoading={qisLoading}
            setShowScore={setShowScore}
            setcurObject={setcurObject}
            answerReveal={answerReveal}
            quizQuestion={quizQuestion}
            answerNext={answerNext}
            answerPrev={answerPrev}
            formativeAns={formativeAns}
            markComplete={markComplete}
            setQuizQuestion={setQuizQuestion}
            setQIsLoading={setQIsLoading}
            setAnswerReveal={setAnswerReveal}
            setFormativeAns={setFormativeAns}
            currentQuestion={currentQuestion}
            setFeedbackButton={setFeedbackButton}
            setCurrentQuestion={setCurrentQuestion}
            getAssignmentDataPost={getAssignmentDataPost}
            handleAnswerOptionClick={handleAnswerOptionClick}
          />
        ) : (
          ""
        )}

        {curObject.qtype == 3 &&
          (curObject.miniassignmenttype == undefined ||
            (curObject.miniassignmenttype != undefined &&
              curObject.miniassignmenttype == 2)) &&
          curObject.userOP.edited != undefined &&
          curObject.userOP.edited == 1 ? (
          <EditMiniAssignment
            totalminiScore={totalminiScore}
            setEditmini={setEditmini}
            editmini={editmini}
            getAssignmentDataPost1={getAssignmentDataPost1}
            autoScorCal={autoScorCal}
            correct={correct}
            setCheckbox={setCheckbox}
            miniloader={miniloader}
            setMiniloader={setMiniloader}
            answerNext={answerNext}
            answerPrev={answerPrev}
            miniopen={miniopen}
            setMiniopen={setMiniopen}
            Button={Button}
            imgUrl={imgUrl}
            classes={classes}
            produce={produce}
            getQuiz={getQuiz}
            setScore={setScore}
            saveResponse={saveResponse}
            btnClick={btnClick}
            showScore={showScore}
            curObject={curObject}
            miniScore={miniScore}
            sMiniScore={sMiniScore}
            assignment={assignment}
            handleClose={handleClose}
            quizQuestion={quizQuestion}
            setShowScore={setShowScore}
            setQuizQuestion={setQuizQuestion}
            isSubmittingg={isSubmittingg}
            qisLoading={qisLoading}
            setQIsLoading={setQIsLoading}
            assignmentLoad={assignmentLoad}
            currentQuestion={currentQuestion}
            setAssignmentLoad={setAssignmentLoad}
            handleImageChange={handleImageChange}
            setCurrentQuestion={setCurrentQuestion}
            setAnsFcount={setAnsFcount}
            ansFcount={ansFcount}
            handleMiniDesOption={handleMiniDesOption}
            pissueTextUpload={pissueTextUpload}
            setPIssueTextUpload={setPIssueTextUpload}
            editOpenConfirm={editOpenConfirm}
            setEditOpenConfirm={setEditOpenConfirm}
            resubmittedData={resubmittedData}
            setresubmittedData={setresubmittedData}
            openMiniFeedback={openMiniFeedback}
            setOpenMiniFeedback={setOpenMiniFeedback}
            setFeedback={setFeedback}
            feedback={feedback}
          />
        ) : (
          curObject.qtype == 3 &&
          curObject.miniassignmenttype !== "3" &&
          curObject.miniassignmenttype !== "4" && (
            <MiniAssignment
              totalminiScore={totalminiScore}
              autoScorCal={autoScorCal}
              correct={correct}
              setCheckbox={setCheckbox}
              miniloader={miniloader}
              setMiniloader={setMiniloader}
              answerNext={answerNext}
              answerPrev={answerPrev}
              miniopen={miniopen}
              setMiniopen={setMiniopen}
              Button={Button}
              imgUrl={imgUrl}
              classes={classes}
              produce={produce}
              getQuiz={getQuiz}
              setScore={setScore}
              saveResponse={saveResponse}
              btnClick={btnClick}
              showScore={showScore}
              curObject={curObject}
              miniScore={miniScore}
              sMiniScore={sMiniScore}
              assignment={assignment}
              handleClose={handleClose}
              quizQuestion={quizQuestion}
              setShowScore={setShowScore}
              setQuizQuestion={setQuizQuestion}
              isSubmittingg={isSubmittingg}
              setQIsLoading={setQIsLoading}
              assignmentLoad={assignmentLoad}
              currentQuestion={currentQuestion}
              setAssignmentLoad={setAssignmentLoad}
              handleImageChange={handleImageChange}
              setCurrentQuestion={setCurrentQuestion}
              setAnsFcount={setAnsFcount}
              ansFcount={ansFcount}
              handleMiniDesOption={handleMiniDesOption}
              questionArray={questionArray}
              setQuestionArray={setQuestionArray}
              pissueTextUpload={pissueTextUpload}
              setPIssueTextUpload={setPIssueTextUpload}
              deadLine={deadLine}
              setDeadLine={setDeadLine}
            />
          )
        )}

        {curObject.qtype == "course" ? (
          <>
            <div
              className={classes.heading}
              style={{ cursor: "pointer" }}

            >
              {/* <h2 className="size-m-b">Course Feedback</h2> */}
              <button
                className="btnQuizLD"
                style={{

                  color: "#3372B5",
                  backgroundColor: "white",
                  borderRadius: "8px",
                  fontSize: "15px",
                  width: "200px",
                  fontWeight: "600",

                  border: "2px solid #3372B5",
                }}
                onClick={() => {

                  getUpdatefeedback("cr", "get");
                  setCInstr("");


                }}
              >
                Course Feedback
              </button>

            </div>

          </>
        ) : (
          ""
        )}

        {curObject.qtype == "instructor" ? (
          <div>
            <h2 className="size-m-b" style={{ margin: "1rem" }}>Instructor Feedback</h2>
            <div
              style={{
                display: "grid",
                gap: "2rem",
                gridTemplateColumns: "20rem 20rem 20rem",
                margin: "1rem",

              }}>
              {instr.map((item, i) => {
                return (

                  <div style={{ display: "flex", alignItems: "center", margin: "2rem", gap: "1rem", }}>

                    <div>
                      <Avatar
                        style={{
                          cursor: "pointer",
                          height: "6rem",
                          width: "6rem",
                          margin: "auto",
                        }}

                        src={`https://${Constants.DOMAIN
                          }/${config.aws_org_id.toLowerCase()}-resources/instructors/${item.emailid}.jpg`}

                      />


                    </div>
                    <button
                      className="item"
                      style={{

                        color: "#3372B5",
                        backgroundColor: "white",
                        borderRadius: "8px",
                        fontSize: "15px",
                        height: "28px",
                        lineHeight: "13px",
                        fontWeight: "600",
                        width: "100px",
                        border: "2px solid #3372B5",
                      }}
                      onClick={() => {
                        getUpdatefeedback("ir", "get", "", item);
                        setCInstr(item);
                      }}
                    >
                      {item.name}
                    </button>
                  </div>

                );
              })}
            </div>


          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  const handleCheck = (i, idx) => {
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].Qsubmit = "true";
        v[currentQuestion].iopts.forEach((opt) => {
          opt.Selected = false;
        });
      })
    );
    setQuizQuestion((currentQues) =>
      produce(currentQues, (v) => {
        v[currentQuestion].iopts[idx].Selected = true;
        v[currentQuestion].iopts[idx].Myselection = "true";
      })
    );
    setFeedbackButton({ ...feedbackButton, disableSubmit: false });
  };

  return (
    <div style={{ height: "100%", width: "100%", overflowY: "auto" }}>
      <div style={{ width: "100%", minHeight: "50px" }}>
        {modallans()}
        {modalFeedBack()}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              fontSize: "18px",
              paddingLeft: "2%",
              paddingTop: "1%",
              fontWeight: "500",
            }}
          >
            {curObject.otitle}
          </div>
          <span
            style={{
              float: "right",
              cursor: "pointer",
              color: "black",
              fontSize: "26px",
              paddingRight: "20px",
            }}
            onClick={() => {
              handleClose();
              setTryAgain(false);
              setFormativeAns([]);
              getQuiz(curObject);
            }}
          >
            x
          </span>
        </div>
      </div>

      {loaded == true ? (
        <div style={{ width: "100%", padding: "12px" }}>
          <div ref={full} className={classes.videoSection}>
            {curObject.nenabled == true || curObject.nenabled == "true"
              ? renderObjects()
              : unitLockedView()}

            {curObject.otype === "Interactivity" ||
              curObject.otype === "interactivity" ||
              curObject.otype === "pdf" ? (
              <IconButton
                style={{ display: "flex", float: "right", padding: 0 }}
                onClick={() => {
                  toggleFullScreen();
                  onFullScreen();
                }}
                className={classes.bottomIcons}
              >
                {fullscreen ? (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <Fullscreen
                      fontSize="large"
                      className={classes.volumeButton}
                    />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={
                      <p style={{ fontSize: "13px" }}>
                        Click here for exit full-screen mode
                      </p>
                    }
                    placement="top"
                  >
                    <FullscreenExitIcon
                      fontSize="large"
                      className={classes.volumeButtonExit}
                      style={{ bottom: "200px" }}
                    />
                  </Tooltip>
                )}
              </IconButton>
            ) : null}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90px",
              width: "100%",
              padding: "2%",
            }}
          >
            {curObject.nenabled == true || curObject.nenabled == "true"
              ? renderButtons()
              : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default CoursePlayer;
