import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
import { Auth } from "aws-amplify";
import { Constants } from "../../config/constants";
import config from "../../config/aws-exports";
import DashboardIcon from "../../assets/svgjs/DashboardIcon";
import MyProgramsIcon from "../../assets/svgjs/MyProgramsIcon";
import EventsIcon from "../../assets/svgjs/EventsIcon";
import Announcements from "../../assets/svgjs/Announcements";
import Tests from "../../assets/svgjs/Tests";
import edLogo from "../../assets/images/edlogo.png";
import swal from "sweetalert";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import { Link, useLocation } from "react-router-dom";

const Aside = ({ toggled, handleToggleSidebar }) => {
  const [active, setactive] = useState("");
  let location = useLocation();

  let userDetails = useSelector(authData);

  const dispatch = useDispatch();

  useEffect(() => {
    setactive(userDetails.sideactive);
    return () => { };
  }, []);

  useEffect(() => {
    get();
    CheckSession();
    return () => { };
  }, [location]);

  async function CheckSession() {
    const sessionCheck = await localStorage.getItem("sessionStore");

    try {
      let session = await Auth.currentSession();

      if (sessionCheck === null || sessionCheck === false) {
        if (session == undefined) {
          await localStorage.setItem("sessionStore", true);

          Auth.signOut();
          dispatch(awsSignOut());
          swal("Session Time Out");
        }
      }
    } catch {
      if (sessionCheck === null || sessionCheck === false) {
        await localStorage.setItem("sessionStore", true);

        Auth.signOut();
        dispatch(awsSignOut());
        swal("Session Time Out");
      }
    }
  }

  function get() {
    let sdata = { ...userDetails };

    if (location.pathname == "/dashboard") {
      sdata.sideactive = "/dashboard";
      dispatch(awsSignIn(sdata));
      setactive("/dashboard");
    } else if (
      location.pathname == "/myPrograms" ||
      location.pathname == "/MyPrograms/programs"
    ) {
      sdata.sideactive = "/myPrograms";
      dispatch(awsSignIn(sdata));
      setactive("/myPrograms");
    } else if (location.pathname == "/event") {
      sdata.sideactive = "/event";
      dispatch(awsSignIn(sdata));
      setactive("/event");
    } else if (location.pathname == "/applyProgram") {
      sdata.sideactive = "/applyProgram";
      dispatch(awsSignIn(sdata));
      setactive("/applyProgram");
    }
  }

  function setClass(val) {
    let sdata = { ...userDetails };
    sdata.sideactive = val;
    dispatch(awsSignIn(sdata));
    setactive(val);
  }

  return (
    <ProSidebar
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Link
            to="/dashboard"
            className="sidebar-btn"
            rel="noopener noreferrer"
            onClick={() => {
              setClass("/dashboard");
            }}
          >
            <img
              src={`https://${Constants.DOMAIN
                }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo.jpg`}
              alt=""
              style={{ height: "7.5rem" }}
            />
          </Link>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu>
          <MenuItem
            className={`${active === "/dashboard" ? "side-nav__item--active" : "null"
              }`}
            icon={<DashboardIcon className="side-nav__icon" />}
            onClick={() => {
              setClass("/dashboard");
            }}
          >
            Dashboard
            <Link to="/dashboard" />
          </MenuItem>

          <MenuItem
            className={`${active === "/myPrograms" ? "side-nav__item--active" : "null"
              }`}
            icon={<MyProgramsIcon className="side-nav__icon" />}
            onClick={() => {
              setClass("/myPrograms");
            }}
          >
            My Programs
            <Link to="/myPrograms" />
          </MenuItem>

          <MenuItem
            className={`${active === "/event" ? "side-nav__item--active" : "null"
              }`}
            icon={<EventsIcon className="side-nav__icon" />}
            onClick={() => {
              setClass("/event");
            }}
          >
            Calendar
            <Link to="/event" />
          </MenuItem>

          <MenuItem
            className={`${active === "/announcements" ? "side-nav__item--active" : "null"
              }`}
            icon={
              <Announcements className="side-nav__icon1" />
            }
            onClick={() => {
              setClass("/announcements");
            }}
          >
            Announcements
            <Link to="/announcements" />
          </MenuItem>

          <MenuItem
            className={`${active === "/tests" ? "side-nav__item--active" : "null"
              }`}
            icon={
              <Tests className="side-nav__icon2" style={{ padding: "0.5rem" }} />
            }
            onClick={() => {
              setClass("/tests");
            }}
          >
            Semester End Exam
            <Link to="/tests" />
          </MenuItem>

          <MenuItem
            className={`${active === "/certificates" ? "side-nav__item--active" : "null"
              }`}
            icon={
              <Tests className="side-nav__icon2" style={{ padding: "0.5rem" }} />
            }
            onClick={() => {
              setClass("/certificates");
            }}
          >
            Certificates
            <Link to="/certificates" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <a
            href="https://enhanzed.com/"
            target="_blank"
            className="sidebar-btn"
            rel="noopener noreferrer"
          >
            <div className="legal">
              <p>&copy; 2024</p>
              Powered by <img src={edLogo} alt="" />
            </div>
          </a>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
