import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import { Box, Button, Paper } from '@material-ui/core';

const labels = {
    1: 'Not Applicable',
    2: 'Strongly Disagree',
    3: 'Disagree',
    4: 'Agree',
    5: 'Strongly Agree',
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    container: {
        margin: "0rem 6rem",
        '@media (max-width: 500px)': {
            margin: "0rem 2rem",
        },
    },
    modal: {
        height: 'fit-content!important',
        width: "60%!important",
        margin: "auto!important",
        '@media (max-width: 770px)': {
            width: "90%!important",
            height: "72vh!important",
            overflowY: "scroll",
        },
    },
}));

export default function FeedBack({ close, Questions, cri, feedbackF, cinstr, showCommentBox, markComplete, handleClose, setOpen1, showMarkComplete, setshowMarkComplete, feedbackSubmited }) {
    const [value, setValue] = useState(-1);
    const [hover, setHover] = useState(-1);
    const [bstatus, setBstatus] = useState(true);
    const [answer, setAnswer] = useState({});
    const [comments, setComments] = useState("");
    const [currentQuestion, setCurrentQuestion] = useState(0);


    const classes = useStyles();

    function nextClick() {
        if ((currentQuestion < Questions.length - 1) && value != -1) {
            let t = { ...answer };
            if (showCommentBox && cri == "cr") {

                t[`q${currentQuestion + 1}`] = {};
                t[`q${currentQuestion + 1}`].rating = value;
                t[`q${currentQuestion + 1}`].comment = comments;

                console.log("txxxxxxxx", t)
                setComments("")
            }

            else {
                t[`q${currentQuestion + 1}`] = value;
            }
            setAnswer(t);
            setCurrentQuestion(currentQuestion + 1);
            setValue(-1);
            setHover(-1);
            setBstatus(true);

        } else if ((currentQuestion == Questions.length - 1) && value != -1) {
            let t = { ...answer };
            if (showCommentBox && cri == "cr") {
                t[`q${currentQuestion + 1}`] = {};
                t[`q${currentQuestion + 1}`].rating = value;
                t[`q${currentQuestion + 1}`].comment = comments;

                setComments("")
            }

            else {
                t[`q${currentQuestion + 1}`] = value;
            }

            setAnswer(t);
            feedbackF(cri, "update", t, cinstr);

            if (markComplete != undefined) {
                setshowMarkComplete(true)
            }
            else {
                close(false);
            }


        }
    }

    return (
        <div className={classes.root}>
            <Paper elevation={3} className={classes.modal}>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", height: "50px", marginRight: "15px" }}> <span style={{ fontSize: "20px", fontWeight: "bold", cursor: "pointer" }} onClick={() => { close(false); }}>X</span> </div>
                {
                    showMarkComplete ? (

                        <div style={{ height: "50rem", width: "80rem", alignContent: "center" }}>

                            {
                                feedbackSubmited && (<h2 style={{ marginLeft: "12rem", padding: "2rem" }}>

                                    Feedback already submitted
                                </h2>)
                            }


                            <button
                                className="btnQuizLD"
                                style={{
                                    width: "200px",
                                    border: "3px solid white",
                                    fontSize: "15px",
                                    marginLeft: "15rem"
                                }}
                                onClick={() => {
                                    markComplete();
                                    setOpen1(false);
                                    close(false);


                                    if (cri === "cr") {
                                        setTimeout(() => {
                                            handleClose();
                                        }, 5000);
                                    }

                                  
                                }}
                            >
                                Mark Complete
                            </button>



                        </div>) : (

                        <div className={classes.container}>
                            <h2
                                style={{
                                    paddingBottom: "15px",
                                }}
                            >
                                Dear Learner, Your feedback is extremely valuable for the
                                University.It helps the University to improve its academic offerings
                                continuously.
                            </h2>
                            <h2 style={{ paddingBottom: "15px" }}>
                                <span>Question {currentQuestion + 1}</span>/
                                {Questions.length}
                            </h2>
                            {cinstr != "" ?
                                <h2 style={{ paddingBottom: "15px" }}>
                                    Feedback for Instructor - {cinstr.name}
                                </h2> : ""}
                            <div style={{ fontSize: "22px", textAlign: "center", marginRight: "30px" }}>
                                {currentQuestion + 1}. {Questions[currentQuestion]}
                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100px", flexDirection: "column" }}>

                                <Rating
                                    name="hover-feedback"
                                    value={value}
                                    precision={1}
                                    onChange={(event, newValue) => {
                                        setValue(newValue);
                                        if (newValue == null || newValue == undefined || newValue == -1) {
                                            setBstatus(true);
                                        } else {
                                            setBstatus(false);
                                        }

                                    }}
                                    onChangeActive={(event, newHover) => {
                                        setHover(newHover);
                                    }}
                                />
                                <div>
                                    {<Box ml={2} style={{ fontSize: "16px", fontWeight: "bold" }}>Response : {labels[hover !== -1 ? hover : value]}</Box>}
                                </div>


                            </div>

                            {(showCommentBox && cri == "cr") && (<textarea
                                type="text"

                                value={comments}
                                style={{
                                    flexGrow: "1",
                                    height: "10rem",
                                    width: "100%",
                                    outline: "none",
                                    fontSize: "1.5rem",
                                    paddingLeft: "1rem",
                                    border: "1px solidrgb(239, 244, 248)",
                                    boxShadow: "0px 0px 5px 0px lightgrey",
                                    padding: "1rem",
                                    lineHeight: "2.5rem"
                                }}

                                onChange={(e) => setComments(e.target.value)}

                            />)
                            }

                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "2rem", }}>
                                <Button variant="contained" color="primary" onClick={nextClick}
                                    disabled={showCommentBox && cri == "cr" ? (bstatus || comments.length === 0) : bstatus}

                                >
                                    {currentQuestion == Questions.length - 1 ? "Submit" : "Next"}
                                </Button>
                            </div>
                        </div>)
                }

            </Paper >
        </div >
    );
}
